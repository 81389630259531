<template>
  <v-app>
    <notifications group="alert" position="top center">
      <template slot="body" slot-scope="props">
        <div class="vue-notification">
          <a class="notification-title">
            {{ props.item.title }}
          </a>
          <a class="notification-close" @click="props.close">
            <i class="mdi mdi-close-circle"></i>
          </a>
          <div class="notification-content" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
import Utils from '@/services/Utils'

export default {
  name: 'DashboardIndex',
  components: {
    DashboardCoreAppBar: () => import('@/components/dashboard/core/AppBar'),
    DashboardCoreDrawer: () => import('@/components/dashboard/core/Drawer'),
    DashboardCoreView: () => import('@/components/dashboard/core/View'),
  },
  data: () => ({
    expandOnHover: false,
  }),
  beforeMount: function () {
    const user = Utils.getUser()
    if (!window.strings) {
      const cacheStrings = Utils.getStorage('strings')
      if (cacheStrings) {
        window.strings = cacheStrings
      } else {
        window.strings = {}
      }
    }
    if (!user) {
      this.$router.navigate.replace({ path: '/' })
    }
  },
}
</script>
